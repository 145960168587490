<template>
  <b-card body-class="p-0">
    <b-alert
      v-if="loadingFetchAncillary"
      show
      variant="warning"
      class="mt-1 px-2 py-1 d-flex-center gap-2 justify-content-start"
    >
      <b-spinner
        variant="warning"
        tag="span"
      />
      <span>
        {{ $t('loading') }}
      </span>
    </b-alert>
    <div v-else-if="isEmpty(serviceOptions)">
      <b-alert
        show
        variant="warning"
        class="mt-1"
      >
        <div class="alert-body text-center text-warning">
          <feather-icon
            class="mr-25"
            icon="FrownIcon"
            size="25"
          />
          <span>
            {{ $t('flight.noAncillary') }}
          </span>
        </div>
      </b-alert>
    </div>

    <b-card
      v-else
      class="mb-0 border-0"
      body-class="p-0"
    >
      <b-row>
        <!-- ANCHOR - Chặng bay -->
        <b-col md="5">
          <b-form-group
            :label="$t('flight.flightSegment')"
            label-for="chang-bay"
            class="pb-25 mb-0"
          >
            <v-select
              v-model="segment"
              input-id="chang-bay"
              label="segmentData"
              class="select-size-sm mr-1 w-100"
              :clearable="false"
              :options="segmentOptions"
              :placeholder="$t('flight.placeholderSelect')"
            >
              <template #option="{segmentData}">
                <p class="text-dark">
                  {{ $t('flight.airline') }}:
                  <span class="font-weight-bold">
                    {{ segmentData.source ? segmentData.source : segmentData.airline }}
                  </span>
                </p>
                <p class="text-dark">
                  {{ $t('flight.flightSegment') }}:
                  <span class="font-weight-bold">
                    {{ segmentData.departure.IATACode }}-{{ segmentData.arrival.IATACode }} ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </p>
              </template>

              <template #selected-option="{segmentData}">
                <p class="font-small-4 text-dark mb-0">
                  <span class="font-weight-bolder">
                    {{ segmentData.source ? segmentData.source : segmentData.airline }}
                  </span>
                  |
                  <span class="font-weight-bolder">
                    {{ `${segmentData.departure.IATACode} - ${segmentData.arrival.IATACode}` }} ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </p>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="my-50">

      <!-- ANCHOR - Passenger -->
      <div v-if="passengersData">
        <b-row
          v-for="(passenger, index) in passengersData"
          :key="index"
          class="w-100 m-0"
        >
          <b-col
            class="px-0 mt-1 mt-md-0 pb-25 pb-md-1 pt-25"
            md="4"
          >
            {{ $t('flight.passenger') }}:
            <span class="font-weight-bolder">
              {{
                passenger.firstName && passenger.lastName
                  ? `${passenger.title} ${passenger.lastName} ${passenger.firstName}`
                  : `${$t(`reservation.${passenger.paxType}`)} #${index + 1}`
              }}
            </span>
          </b-col>

          <!-- ANCHOR - Gói dịch vụ -->
          <b-col
            cols="12"
            md="5"
            class="px-0"
          >
            <v-select
              :id="`service-packages-${index}`"
              v-model="passenger.servicePackage"
              class="ssrs_package_select select-size-sm w-100"
              label="code"
              :filter-by="filterBy"
              :placeholder="$t('flight.servicePlaceholder')"
              :disabled="disableServicePackage"
              :options="serviceListOptions"
              @input="(val) => setDefaultPassengerAmount(val, passenger)"
            >
              <template #option="data">
                <div
                  class="d-flex justify-content-between"
                  :class="isMobileView ? 'font-small-1' : 'font-small-4'"
                >
                  <div>
                    <span
                      class="mr-75"
                      :class="resolveVariantByBagg(data)"
                    >
                      {{
                        data.ssrName === 'ssrBags'
                          ? resolveSsrBagsName(data)
                          : resolveSsrNameByCode(data.code)
                      }}
                    </span>
                    <span class="font-weight-bolder text-warning">
                      {{
                        formatCurrency(resolvePriceService(data, passenger))
                      }}
                    </span>
                  </div>
                  <div v-if="data.ssrName === 'ssrOthers' && data.segmentData && data.segmentData.source && ['TH'].includes(data.segmentData.source) && data.ssrValue">
                    {{ data.ssrValue }}
                  </div>
                </div>
              </template>
              <template #selected-option="data">
                <div :class="`d-flex align-items-center font-weight-bolder ${isMobileView ? 'font-small-1' : 'font-small-4'}`">
                  <div>
                    <span>
                      {{
                        data.ssrName === 'ssrBags'
                          ? resolveSsrBagsName(data)
                          : resolveSsrNameByCode(data.code)
                      }}
                    </span>
                    (<span class="text-warning">
                      {{
                        formatCurrency(resolvePriceService(data, passenger))
                      }}
                    </span>)
                  </div>
                  <div
                    v-if="data.ssrName === 'ssrOthers' && data.segmentData && data.segmentData.source && ['TH'].includes(data.segmentData.source) && data.ssrValue"
                    class="ml-75"
                  >
                    ({{ data.ssrValue }})
                  </div>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- ANCHOR - Số lượng mua -->
          <b-col
            cols="4"
            md="2"
            class="mt-50 mt-md-0 px-0 px-md-1"
          >
            <v-select
              :id="`service-packages-amount-${index}`"
              v-model="passenger.serviceAmount"
              class="select-size-sm w-100"
              label="text"
              :placeholder="$t('flight.Amount')"
              :clearable="false"
              :disabled="disableServicePackage || (segment && ['VN1A'].includes(segment.source)) || !passenger.servicePackage"
              :options="amountOptions"
            >
              <template #option="{text}">
                <span class="font-small-3">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
              <template #selected-option="{text}">
                <span class="font-small-3 pl-50">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="!isHideFunction"
        class="text-center mt-50"
      >
        <b-button
          class="mr-2"
          variant="outline-warning"
          @click="handleClearForm"
        >
          {{ $t('flight.clear') }}
        </b-button>

        <b-button
          variant="primary"
          @click="handleAddServices"
        >
          {{ $t('flight.addService') }}
        </b-button>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BAlert, BButton, BFormGroup, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed,
  toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import { v4 as uuidv4 } from 'uuid'

import { resolveSsrBagsName, resolveSsrNameByCode, resolveVariantByBagg } from '@/constants/selectOptions'
import store from '@/store'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useCreateBookingHandle from '@flightv2/reservation/useCreateBookingHandle'
import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  props: {
    ssrBags: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  /*
  ----- CÁC HÃNG MUA HÀNH LÝ KHI TẠO BOOKING -----
  - MUA THEO HÀNH TRÌNH: QH, VN1A, VJ, VU.
  - MUA THEO CHẶNG: AK, F1, TH.
  */
  setup(props) {
    const { toastSuccess, toastWarning, toastError } = useToast()

    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      getAddonsData,
      loadingFetchAncillary,
    } = useBookingHandle()
    const {
      isHideFunction,
    } = useCreateBookingHandle()
    const { ssrBags, isCombination } = toRefs(props)

    const passengersData = ref(null)
    function setBlankDataPassengers() {
      passengersData.value = cloneDeep(props.passengerDataToAdd.filter(passenger => passenger.paxType !== 'INFANT'))
    }
    // Set passenger data
    watch(() => props.passengerDataToAdd, () => {
      setBlankDataPassengers()
    }, { deep: true, immediate: true })

    // SECTION  SERVICE ADDONS
    const serviceOptions = computed(() => {
      if (isEmpty(ssrBags.value)) return []

      return {
        name: 'ssrBags',
        data: ssrBags.value.map(item => ({
          ...item,
          ssrName: 'ssrBags',
          ssrId: uuidv4(),
        })),
      }
    })

    function getSsrDataBySource(val, trip, flightsName) {
      if (['VN1A'].includes(trip.source)) {
        const result = val.data.filter(item => item.segmentIds.map(item => item.split('-')[0]).includes(trip.departure.IATACode)
                                   && (isCombination.value ? true : (item?.itineraryId === trip.airlineItineraryId)))
        return result
      }

      if (['VJ'].includes(trip.source)) {
        const result = val.data.filter(item => (item.flights === flightsName) && trip.segments.map(s => s.segmentId).includes(item.segmentIds[0])) // (item.segmentIds[0] === trip.segments[0].segmentId))
        return result
      }

      if (['VU', 'TH'].includes(trip.source)) {
        const result = val.data.filter(item => (item.flights === flightsName) && item.segmentIds.includes(trip.segmentId ? trip.segmentId : trip.segments[0].segmentId))
        return result
      }

      if (['1A'].includes(trip.source)) {
        const result = val.data.filter(item => (item.flights.includes(flightsName))
        // && item.segmentIds.includes(trip.segmentId
        //   ? trip.segmentId
        //   : trip.segments[0].segmentId)
        && item.segmentIds.some(s => trip.segments.map(s => s.segmentId).includes(s)))
        return result
      }

      return val.data.filter(item => item.flights === flightsName)
    }

    // SECTION SEGMENT
    const segment = ref(null)
    const segmentOptions = ref([])

    watch(() => serviceOptions.value, val => {
      segment.value = null
      segmentOptions.value = []
      setBlankDataPassengers()

      if (!isEmpty(val)) {
        const result = []
        if (val.name === 'ssrBags') {
          props.selectedTrip.forEach((trip, tripIndex) => {
            if (['AK'].includes(trip.source)) { // bỏ TH: do long2k gộp chặng vào (https://discord.com/channels/1054696448110903327/1055496072857919529/1286024508582662294)
              trip.segments.forEach(segment => {
                result.push({ ...segment, source: trip.source }) // Theo chặng
              })
            } else {
              const tripData = !isCombination.value ? trip : {
                ...trip,
                tripId: tripIndex + 1,
              }

              result.push(tripData) // Theo hành trình
            }
          })
        }

        result.forEach((dataFlight, index) => { // data ở đây có thể là hành trình hoặc chặng
          const flightsName = ['1A'].includes(dataFlight?.source ?? '')
            ? dataFlight.segments.map(s => `${s.departure.IATACode}-${s.arrival.IATACode}`).join(' | ')
            : `${dataFlight.departure.IATACode}-${dataFlight.arrival.IATACode}`

          const mappedAddonsData = {
            segmentData: {
              ...dataFlight,
              segmentIndex: index + 1,
              flights: flightsName,
            },
            ssrData: getSsrDataBySource(val, dataFlight, flightsName),
          }

          if (mappedAddonsData.ssrData.length > 0) {
            segmentOptions.value.push(mappedAddonsData)
          }
        })
      }
    }, { deep: true, immediate: true })

    // SECTION ADDONS OPTION
    const serviceListOptions = ref([])
    const amountOptions = ref([])

    watch(() => segment.value, val => {
      amountOptions.value = []
      serviceListOptions.value = []
      setBlankDataPassengers()

      if (val) {
        const data = val.ssrData.map(item => ({
          segmentData: val.segmentData,
          ...item,
        }))

        if (!isEmpty(data) && data[0].ssrName === 'ssrBags') {
          serviceListOptions.value = data.sort((a, b) => (a?.details[0]?.weight || 1) - (b?.details[0]?.weight || 0))
          if (!isEmpty(serviceListOptions.value) && serviceListOptions.value.some(i => ['VJ', 'VZ'].includes(i.airline))) {
            serviceListOptions.value = sortBy(serviceListOptions.value, bagItem => bagItem.code)
          }
        }

        if (data[0]?.segmentData?.source?.includes('QH') && !isEmpty(data)) {
          const option = [1, 2, 3, 4, 5, 6]
          amountOptions.value.push(...option)
        } else {
          amountOptions.value.push(1)
        }
      }
    }, { deep: true })

    function resolvePriceService(service, passenger) {
      const fare = service.fares.find(fare => fare.paxType === passenger.paxType) || service.fares[0]
      return fare.total || 0
    }

    // handle set setDefaultPassengerAmount
    const setDefaultPassengerAmount = (val, passenger) => {
      passenger.serviceAmount = val !== null ? 1 : null
    }

    // handle clear form
    function handleClearForm() {
      segment.value = null
      // serviceSelected.value = null
      setBlankDataPassengers()
    }

    // ANCHOR - HANDLE ADD-ONS --- Add service
    function handleAddService(ssrsToAdd) {
      for (const ssrData of ssrsToAdd) {
        const rawData = cloneDeep(getAddonsData.value)

        // ANCHOR - Thêm số lượng gói
        if (['QH'].includes(ssrData.source ?? ssrData.airline)) {
          const existingSsrIndex = getAddonsData.value.findIndex(ssr => ssr.paxId === ssrData.paxId && ssr.ssrId === ssrData.ssrId)

          if (existingSsrIndex === -1) {
            rawData.push(ssrData)
          } else {
            // const totalAmount = rawData[existingSsrIndex].amount + ssrData.amount
            // if (totalAmount > 1) {
            //   toastError({
            //     title: 'Không thể thêm số lượng quá 1 gói hành lý / hành trình!',
            //   })
            //   return
            // }

            rawData[existingSsrIndex].amount += ssrData.amount
          }
        } else {
          rawData.push(ssrData)
        }

        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddonsSelectData`, rawData)
        handleClearForm()
      }

      toastSuccess({ title: 'Thêm dịch vụ thành công!' })
    }

    // SECTION DISABLE FORM
    // const disableSegment = computed(() => !serviceSelected.value)
    const disableServicePackage = computed(() => !segment.value)

    function handleAddServices() {
      const checkArrayItems = passengersData.value.every(item => item.servicePackage === null && item.serviceAmount === null)

      if (!segment.value || checkArrayItems) {
        toastWarning({ title: 'Vui lòng chọn gói dịch vụ!' })
        return
      }

      // NOTE DATA SSR
      const dataSSR = passengersData.value.filter(p => p.servicePackage).map(passenger => {
        const { servicePackage } = passenger
        const data = {
          // ========== Data Create Booking ==========
          code: servicePackage.code,
          type: '',
          name: '',
          ...(servicePackage.details && !isEmpty(servicePackage.details[0]) && {
            details: servicePackage.details.map(details => ({
              weight: String(details.weight),
              unit: details.unit,
            })),
          }),
          ...(['VJ', 'VZ', 'VN', '1A', 'VN1A'].includes(servicePackage.source ?? servicePackage.airline) && { itineraryId: servicePackage.segmentData.airlineItineraryId ?? '' }),
          ...(['TH'].includes(servicePackage.airline) && { itineraryId: servicePackage.segmentData.itineraryId }),
          ...(['VU'].includes(servicePackage.airline) && { itineraryId: servicePackage.segmentData.segments[0].segmentValue }),
          segmentId: ['VN', 'VN1A'].includes(servicePackage.source ?? servicePackage.airline)
            ? ['ssrOthers'].includes(servicePackage.ssrName)
              ? String(servicePackage?.segmentData?.airlineSegmentId)
              : (servicePackage.segmentData.segments[0]?.airlineSegmentId?.toString() || '')
            : isEmpty(servicePackage.segmentIds)
              ? ''
              : servicePackage.segmentIds[0], // NOTE segmentIds từ api list-ancillary
          paxId: passenger.paxId,
          addonValue: ['VJ', 'VZ', 'VN', 'VN1A', '1A', 'TH'].includes(servicePackage.source ?? servicePackage.airline) ? servicePackage.ssrValue : resolvePriceService(servicePackage, passenger),

          // ========== Others ==========
          paxType: passenger.paxType,
          ssrName: servicePackage.ssrName, // toCheck
          ssrId: servicePackage.ssrId, // toCheck

          // ========== Data Show Price ==========
          // HÀNH KHÁCH
          firstName: passenger.firstName,
          lastName: passenger.lastName,

          // CHẶNG BAY
          flights: (servicePackage.airline === 'QH' && servicePackage.ssrName === 'ssrBags')
            ? servicePackage.segmentData.flights
            : servicePackage.flights,

          tripId: servicePackage.segmentData?.tripId ?? servicePackage.segmentData?.segments[0]?.tripId ?? '', // check to add segmentId follow segmentValue[0]

          // HÃNG HÀNG KHÔNG
          airline: servicePackage.airline,

          // GIÁ
          addonPrice: resolvePriceService(servicePackage, passenger),
          amount: passenger.serviceAmount,

          // DỊCH VỤ
          serviceName: servicePackage.details
            ? resolveSsrBagsName(servicePackage)
            : resolveSsrNameByCode(servicePackage.code),

          source: servicePackage.source,
        }
        return data
      })

      const existingPaxSsrs = dataSSR.filter(paxSsr => {
        let existingPax

        if (['VN1A', 'VN'].includes(paxSsr.source ?? paxSsr.airline)) {
          existingPax = undefined
        } else if (['VJ', 'VZ'].includes(paxSsr.source ?? paxSsr.airline)) {
          existingPax = getAddonsData.value.find(
            ssr => (ssr.paxId === paxSsr.paxId)
                && (ssr.flights === paxSsr.flights)
                && (ssr.ssrName === paxSsr.ssrName),
          )

          // Check mua gói 2Kg xách tay VJ
          if (['VJ', 'VZ'].includes(paxSsr.airline) && paxSsr.code === 'Cabin Extra 2k') {
            existingPax = getAddonsData.value.find(
              ssr => (ssr.paxId === paxSsr.paxId) && (ssr.flights === paxSsr.flights) && (ssr.ssrName === paxSsr.ssrName) && (ssr.code === paxSsr.code),
            )
          }
        } else if (['TH'].includes(paxSsr.source)) {
          // handle TH mua 1 gói / chặng
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.flights === paxSsr.flights)
        } else if (['QH'].includes(paxSsr.source ?? paxSsr.airline)) {
          const newKg = Number(paxSsr?.details?.[0]?.weight) * paxSsr.amount
          if (!newKg) {
            existingPax = paxSsr
            toastWarning({ title: 'Lỗi thêm gói dịch vụ, vui lòng thực hiện lại hoặc liên hệ booker để xử lý!' })
          }

          const totalExistKg = getAddonsData.value
            .filter(it => it.ssrName === 'ssrBags'
                       && it.paxId === paxSsr.paxId
                       && it.flights === paxSsr.flights
                       && it.itineraryId === paxSsr.itineraryId)
            .reduce((total, item) => {
              const weight = item?.details?.[0]?.weight
              const result = total + (!Number.isNaN(Number(weight)) ? (Number(weight) * item.amount) : 0)
              return result
            }, 0)

          existingPax = totalExistKg + Number(newKg) > 60 ? paxSsr : undefined
        } else {
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId)
        }

        if (existingPax) {
          const data = existingPax
          if (data.airline === 'AK') {
            data.code = data.serviceName
          }

          // tên gói hiển thị trên toast
          // với TH thì chỉ mua 1 gói / chặng
          const ssrNameToast = ['TH'].includes(existingPax.source) ? 'hành lý' : resolveSsrNameByCode(data.code) || data.code

          const addOnlyOneSsr = `${data.firstName
            ? `${data.lastName} ${data.firstName} `
            : `${data.paxType} ${data.paxId}`
          }  chỉ có thể mua một gói ${ssrNameToast} trên một chặng/hành trình.`

          const errQH = `Hành khách ${data.firstName
            ? `${data.lastName} ${data.firstName} `
            : `${data.paxType} ${data.paxId}`
          } chỉ có thể mua thêm tối đa 60KG hành lý/từng hành trình!`

          toastError({
            title: ['QH'].includes(paxSsr.source ?? paxSsr.airline) ? errQH : addOnlyOneSsr,
          })

          return data
        }

        return existingPax
      })

      if (existingPaxSsrs.length === 0) {
        handleAddService(dataSSR)
      }
    }

    function filterBy(option, label, search) {
      const code = (option?.code ?? '').toUpperCase()
      const searchUpper = (search ?? '').toUpperCase()
      const detailsUpper = (`${option?.details[0]?.weight}` ?? '').toUpperCase()
      return code?.includes(searchUpper) || detailsUpper.includes(searchUpper)
    }

    return {
      handleAddServices,
      handleClearForm,
      setDefaultPassengerAmount,
      resolveSsrNameByCode,
      resolveSsrBagsName,
      resolvePriceService,

      serviceOptions,
      segmentOptions,
      serviceListOptions,
      amountOptions,
      passengersData,
      segment,
      // serviceSelected,

      // disableSegment,
      disableServicePackage,
      formatCurrency,

      isEmpty,
      resolveVariantByBagg,
      convertISODateTime,
      filterBy,
      loadingFetchAncillary,
      isHideFunction,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style lang="scss" scoped>
  .ssrs_package_select {
    ::v-deep .vs__dropdown-option {
      text-wrap: wrap !important;
    }

    ::v-deep .vs__dropdown-option--selected {
      color: black !important;
      &.vs__dropdown-option--highlight {
      color: black !important;
    }
    }
  }
  </style>
